import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import PropertyImage from "./PropertyImage";

function App() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const scriptId = "google-api";
    const existing = document.getElementById(scriptId);
    if (!existing) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src =
        "https://maps.googleapis.com/maps/api/js?client=gme-municipalproperty1&channel=mconnect";
      script.async = true;
      script.defer = true;
      script.addEventListener("load", () => {
        setIsReady(true);
      });
      document.body.appendChild(script);
    } else {
      setIsReady(true);
    }
  }, []);

  return isReady ? (
    <PropertyImage />
  ) : (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="dark" />
      <h6 className="mb-0 ml-3">Loading ... </h6>
    </div>
  );
}

export default withAuthenticationRequired(App);
