// takes date object and converts to UTC string

const datePipe = (date: Date, alternate?: boolean): string => {
  if (!date) {
    return date;
  }
  if (alternate) {
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return date.toISOString().split("T")[0];
};

export default datePipe;
