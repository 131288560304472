import { useState, useEffect } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import datePipe from "./datePipe";

// declare let google: any;

const PropertyImage = (): JSX.Element => {
  const urlParams = new URLSearchParams(window.location.search);
  let address = urlParams.get("address");
  const lat = urlParams.get("lat") || "";
  const lon = urlParams.get("lon") || "";

  // Google API key
  const streetViewApiKey = process.env.REACT_APP_STREETVIEW_API_KEY;
  //
  const streetViewService: google.maps.StreetViewService =
    new google.maps.StreetViewService();
  //
  const directionsService: google.maps.DirectionsService =
    new google.maps.DirectionsService();
  //

  const streetViewConfig: google.maps.StreetViewPanoramaOptions = {
    disableDefaultUI: true,
    fullscreenControl: true,
    imageDateControl: true,
    zoomControl: true,
    scrollwheel: false,
    zoom: 0,
  };
  const panoRequest: google.maps.StreetViewLocationRequest = {
    location: { lat: 0, lng: 0 },
    preference: google.maps.StreetViewPreference.NEAREST,
    radius: 100,
    source: google.maps.StreetViewSource.OUTDOOR,
  };

  const [panorama, setPanorama] = useState<google.maps.StreetViewPanorama>();
  const [historicalPanos, setHistoricalPanos] = useState<any>([]);
  const [hasStreetview, setHasStreetView] = useState(true);

  useEffect(() => {
    if (address) {
      getPanoByAddress();
    } else {
      reverseGeocodingAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reverseGeocodingAddress = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&location_type=ROOFTOP&result_type=street_address&key=${streetViewApiKey}&channel=mconnect`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "OK") {
          if (data.results) {
            address = data.plus_code?.compound_code
              ? data.plus_code?.compound_code
              : data.results[0].formatted_address;
            // address = data.results[0].formatted_address;
            getPanoByAddress();
          }
        } else {
          setHasStreetView(false);
        }
      });
  };

  const getPanoByAddress = () => {
    let requestAddress = address ? address : "";
    fetch(
      `https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${requestAddress}&key=${streetViewApiKey}&channel=mconnect`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "OK") {
          // find a Streetview location on the road
          const request = {
            origin: requestAddress,
            destination: requestAddress,
            travelMode: google.maps.TravelMode.DRIVING,
          };
          directionsService.route(
            request,
            (
              dirData: google.maps.DirectionsResult,
              dirStatus: google.maps.DirectionsStatus
            ) => {
              if (dirStatus === google.maps.DirectionsStatus.OK) {
                panoRequest.location = dirData.routes[0].legs[0].start_location;
                streetViewService.getPanorama(
                  panoRequest,
                  (
                    panoData: google.maps.StreetViewPanoramaData | null,
                    panoStatus: google.maps.StreetViewStatus
                  ) => {
                    if (
                      panoStatus === google.maps.StreetViewStatus.OK &&
                      panoData?.location?.latLng
                    ) {
                      const computedHeading =
                        google.maps.geometry.spherical.computeHeading(
                          panoData.location.latLng,
                          new google.maps.LatLng(
                            parseFloat(lat),
                            parseFloat(lon)
                          )
                        );
                      const newStreetViewConfig = {
                        position: {
                          lat: panoData.location.latLng.lat(),
                          lng: panoData.location.latLng.lng(),
                        },
                        pov: {
                          heading: computedHeading,
                          pitch: 0,
                        },
                        ...streetViewConfig,
                      };
                      setPanorama(
                        new google.maps.StreetViewPanorama(
                          document.getElementById("street-view")!,
                          newStreetViewConfig
                        )
                      );
                      setHistorical(panoData);
                    } else {
                      getPanoByLatLng();
                    }
                  }
                );
              } else {
                getPanoByLatLng();
              }
            }
          );
        } else {
          getPanoByLatLng();
        }
      });
  };

  const getPanoByLatLng = () => {
    // use mpac provided coordinates as fallback if searching by address fails at any point
    // this may not face the right direction
    fetch(
      `https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${lat},${lon}&key=${streetViewApiKey}&channel=mconnect`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "OK") {
          panoRequest.location = new google.maps.LatLng(
            parseFloat(lat),
            parseFloat(lon)
          );

          streetViewService.getPanorama(
            panoRequest,
            (
              panoData: google.maps.StreetViewPanoramaData | null,
              status: google.maps.StreetViewStatus
            ) => {
              if (
                status === google.maps.StreetViewStatus.OK &&
                panoData?.location?.latLng
              ) {
                const computedHeading =
                  google.maps.geometry.spherical.computeHeading(
                    panoData.location.latLng,
                    new google.maps.LatLng(parseFloat(lat), parseFloat(lon))
                  );
                const newStreetViewConfig = {
                  position: {
                    lat: panoData.location.latLng.lat(),
                    lng: panoData.location.latLng.lng(),
                  },
                  pov: {
                    heading: computedHeading,
                    pitch: 0,
                  },
                  ...streetViewConfig,
                };
                setPanorama(
                  new google.maps.StreetViewPanorama(
                    document.getElementById("street-view")!,
                    newStreetViewConfig
                  )
                );
                setHistorical(panoData);
              } else {
                setHasStreetView(false);
              }
            }
          );
        } else {
          setHasStreetView(false);
        }
      });
  };

  const setHistorical = async (data: any) => {
    /**
     *	- the panorama IDs in `data.time` are sorted by date (the date closest to the present occurs first in the array)
     *	- by default, street view will display the date closest to the present (i.e. the first element in the array)
     *	- the time array is dynamic per session
     *		- this means that the attribute is safe to access in the current session but unsafe if the session changes
     *		- must dynamically access this attribute
     */
    const historicalStreetview = data.time.reverse();
    historicalStreetview[0].selected = true;
    // eslint-disable-next-line react/destructuring-assignment
    setHistoricalPanos(historicalStreetview);
    // panorama?.setPano(historicalStreetview[0]);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="px-0">
          <div
            id="street-view"
            className={`${
              historicalPanos && historicalPanos.length > 1
                ? ""
                : "no-historical"
            } ${hasStreetview ? "" : "d-none"}`}
          />
          {historicalPanos && historicalPanos.length > 0 && (
            <Form
              style={{ width: "130px", top: "10px", left: "10px", zIndex: 2 }}
              className="position-absolute"
            >
              <Form.Group controlId="image-select">
                <Form.Control
                  as="select"
                  size="sm"
                  onChange={(e) => {
                    const { value } = e.target;
                    panorama?.setPano(value);
                  }}
                >
                  {historicalPanos.map((item: any) => {
                    const itemDate: any = Object.values(item).filter(
                      (k) => k instanceof Date
                    )[0];
                    return (
                      <option id={item.pano} key={item.pano} value={item.pano}>
                        {datePipe(new Date(itemDate))}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form>
          )}
          {!hasStreetview && (
            <div
              className="d-flex align-items-center justify-content-center w-100"
              style={{
                background: "#f1f1f1",
                borderRadius: "0.5rem",
                height: "100vh",
              }}
            >
              <div className="d-flex align-items-center">
                <div className="text-center p-5">
                  <i
                    className="fas fa-home"
                    aria-label="No Image Found Icon"
                    style={{ fontSize: "2rem" }}
                  />
                  <h3 style={{ fontSize: "1.25rem" }}>
                    No images found for this property
                  </h3>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PropertyImage;
